import { useState, useCallback } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useQueries } from "./useQueries"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useAnalytics } from "./useAnalytics"
import { scrollTo } from "../helpers/scrollTo"
import { useEmarsys } from "./useEmarsys"
import { useCustomerContext } from "./useCustomer"

export const useCart = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    mutations: { CHECKOUT_LINE_ITEMS_REPLACE, CHECKOUT_LINE_ITEM_ADD, CHECKOUT_LINE_ITEMS_REMOVE },
  } = useQueries()
  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { customer } = useCustomerContext()
  const { checkout } = useCheckoutContext()
  const { setCheckout } = useCheckout()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsys()
  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [loadingAttributes, setLoadingAttributes] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)
  const [, dispatch] = globalStateReducer

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)
  const [lineItemAdd] = useMutation(CHECKOUT_LINE_ITEM_ADD)
  const [lineItemsRemove] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE)

  const addToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)
      let alreadyInCart = false

      const lineItems =
        checkout?.lineItems?.map(lineItem => {
          if (lineItem?.variant?.id === variantId) {
            alreadyInCart = true
            return {
              customAttributes: [
                ...lineItem?.customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })),
                ...(customAttributes || []),
              ],
              quantity: lineItem?.quantity + quantity,
              variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems: [...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])],
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setCheckout(data?.checkout)
        dispatch({
          type: "SHOW_CART",
        })
        scrollTo(0)
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate("add", variantId, quantity, normalisedCheckout?.lineItems)
      trackPage(customer, normalisedCheckout)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout?.lineItems, checkoutId, checkoutNormaliser, trackCartUpdate, dispatch, storage, keys?.country]
  )

  const addGiftCardToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)

      const {
        data: { checkoutLineItemsAdd: data, userErrors: errors },
      } = await lineItemAdd({
        variables: {
          checkoutId,
          lineItems: [{ quantity, variantId, customAttributes }],
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setCheckout(data?.checkout)
        dispatch({
          type: "SHOW_CART",
        })
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate("add", variantId, quantity, normalisedCheckout?.lineItems)
      trackPage(customer, normalisedCheckout)
    },
    [lineItemAdd, setErrors, setCheckout, setLoading, checkoutId, checkoutNormaliser, trackCartUpdate, dispatch, storage, keys?.country]
  )

  const removeFromCart = useCallback(
    async variantId => {
      setLoadingRemove(true)
      const quantity = checkout?.lineItems.filter(({ variant }) => variant.id === variantId).map(({ quantity }) => quantity)[0] || 1
      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        .filter(lineItem => lineItem.variant.id !== variantId)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await setCheckout(data?.checkout)
        trackPage(customer, checkoutNormaliser(data?.checkout))
      }
      setLoadingRemove(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoadingRemove, trackCartUpdate, checkout, checkoutId, storage, keys?.country]
  )

  const removeLineItemFromCart = useCallback(
    async lineItemId => {
      setLoadingRemove(true)
      const quantity = checkout?.lineItems?.find(lineItem => lineItem?.id === lineItemId)?.quantity
      const variantId = checkout?.lineItems?.find(lineItem => lineItem?.id === lineItemId)?.variant?.id
      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItemIds = [lineItemId]
      const {
        data: { checkoutLineItemsRemove: data, userErrors: errors },
      } = await lineItemsRemove({
        variables: {
          checkoutId,
          lineItemIds,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setCheckout(data?.checkout)
        trackPage(customer, checkoutNormaliser(data?.checkout))
      }
      setLoadingRemove(false)
    },
    [setErrors, setCheckout, setLoadingRemove, checkout, checkoutId, lineItemsRemove, trackCartUpdate, storage, keys?.country]
  )

  const updateQuantity = useCallback(
    async (variantId, quantity) => {
      console.info("variantId: ", variantId)
      setLoading(true)
      checkout?.lineItems.forEach(item => {
        console.info(item.variant.id)
      })
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.variant.id === variantId ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate(`change`, variantId, quantity, normalisedCheckout?.lineItems)
      trackPage(customer, normalisedCheckout)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, checkoutNormaliser, trackCartUpdate, storage, keys?.country]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, storage, keys?.country]
  )

  const updateAttributes = useCallback(
    async (variantId, customAttributes) => {
      setLoadingAttributes(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity: lineItem.quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoadingAttributes(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoadingAttributes, checkout, checkoutId, storage, keys?.country]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, checkoutId, storage, keys?.country]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    if (checkout?.lineItems?.length > 0) {
      checkout.lineItems.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems))
    }

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        checkoutId,
        lineItems: [],
        countryCode: storage.get(keys?.country),
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) {
      setCheckout(data?.checkout)
      trackPage(customer, checkoutNormaliser(data?.checkout))
    }
    setLoading(false)
  }, [lineItemsReplace, setErrors, setCheckout, setLoading, checkout.lineItems, checkoutId, trackCartUpdate, storage, keys?.country])

  return {
    addGiftCardToCart,
    removeLineItemFromCart,
    addToCart,
    removeFromCart,
    updateQuantity,
    updateVariant,
    updateAttributes,
    loadingAttributes,
    updateItem,
    clearCart,
    loading,
    loadingRemove,
    errors,
  }
}
