import React from "react"
import { Link } from "gatsby"

import { withFooter } from "./withFooter"
import { Outer, Inner, Third, Half, Quarter, Fluid, MegaNavigation, MegaNavigationItem, BottomNavItem, NavWrapper } from "./FooterStyles"
import { NavigationFooter } from "../Navigation/Footer/NavigationFooter"
import { Subscribe } from "../Subscribe/Subscribe"
import { SocialMedia } from "../SocialMedia/SocialMedia"
import { FooterCopyright } from "./Copyright/FooterCopyright"
import { FooterContact as Contact } from "./Contact/FooterContact"
import { PaymentIcons } from "./PaymentIcons/PaymentIcons"
import { Icon } from "../Icon/Icon"
import { StyledContainer } from "../Styled/Container"

export const Footer = withFooter(
  ({ subscribeTitle, footerMegaNavigation, footerBottomNavigation }): JSX.Element => (
    <Outer>
      <StyledContainer>
        <Inner>
          <Half>
            <Contact />
          </Half>
          <Quarter>
            <Subscribe layout={"footer-mobile"}/>
            {footerMegaNavigation?.length && (
              <MegaNavigation>
                {footerMegaNavigation?.map((item) => {
                  return (
                    <MegaNavigationItem key={item.title}>
                      <Icon name={"arrowRightThin"} width={20} />
                      <Link to={item?.url}>{item?.title}</Link>
                    </MegaNavigationItem>
                  )
                })}
              </MegaNavigation>
            )}
          </Quarter>
        </Inner>
        <Inner>
          <Half>
            <Subscribe layout={"footer-desktop"}/>
            <SocialMedia layout={"desktop"} />
          </Half>
          <Quarter>
            <NavWrapper>
              <NavigationFooter />
              <Icon name={"skidmanLogo"} width={80} height={30} />
            </NavWrapper>
          </Quarter>
        </Inner>
        <Inner position={"bottom"}>
          <Fluid align={"start"} layout={"bottomNav"}>
            <FooterCopyright layout={"desktop"}/>
            {footerBottomNavigation?.map((item) => <BottomNavItem key={item?.url} to={item?.url}>{item?.title}</BottomNavItem>)}
            <FooterCopyright layout={"mobile"}/>
          </Fluid>
          <Fluid align={"end"}>
            <PaymentIcons />
          </Fluid>
        </Inner>
      </StyledContainer>
    </Outer>
  )
)
