import { useCallback, useEffect, useState } from "react"
import { useCore } from "./useCore"

export const useEmarsys = () => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const [emarsysLoaded, setEmarsysLoaded] = useState(typeof window !== `undefined` ? !!window.ScarabQueue : false)

  const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

  useEffect(() => {
    if (emarsysLoaded) {
      return
    }

    // Poll until Emarsys is loaded via GTM
    const interval = setInterval(() => {
      if (window.ScarabQueue) {
        setEmarsysLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const track = useCallback((id, payload = null) => {
    const arr = [id]
    if (payload) {
      arr.push(payload)
    }

    if (window.ScarabQueue) {
      if (isDev) {
        console.log(`[Emarsys] ${id} fired\n${JSON.stringify(payload)}`)
      }
      window.ScarabQueue.push(arr)
    } else {
      if (isDev) {
        console.log(`[Emarsys] ${id} fired (ScarabQueue missing)\n${JSON.stringify(payload)}`)
      }
    }
  }, [isDev])

  const identify = useCallback(customer => {
    track("setEmail", `${customer?.email}`)
  }, [track])

  const trackCart = useCallback(lineItems => {
    try {
      if (!lineItems) {
        lineItems = []
      }

      track("cart", lineItems.map(item => ({
        item: decodeShopifyId(item?.variant?.id, "ProductVariant"),
        price: parseFloat(item?.variant?.priceV2?.amount) * item.quantity - item.discountAllocations.reduce((sum, cur) => sum + Number(cur.allocatedAmount.amount), 0),
        quantity: item.quantity,
      })))
    } catch (e) {
      console.log(e)
    }
  }, [decodeShopifyId, track])

  const trackProductView = useCallback(productId => {
    try {
      track("view", `g/${productId}`)
    } catch (e) {
      console.log(e)
    }
  }, [track])

  const trackCategory = useCallback(collection => {
    track("category", collection.title)
  }, [track])

  const trackGo = useCallback(() => {
    track("go")
  }, [track])

  const trackPage = useCallback((customer, cart, collection = null, productId = null) => {
    if (customer) {
      identify(customer)
    }
    trackCart(cart?.lineItems)
    if (productId) {
      trackProductView(productId)
    }
    if (collection) {
      trackCategory(collection)
    }
    trackGo()
  }, [identify, trackCart, trackCategory, trackGo, trackProductView])

  return { emarsysLoaded, identify, trackCart, trackProductView, trackCategory, trackGo, trackPage }
}
